import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ReplayIcon from '@mui/icons-material/Replay';
import Paper from '@mui/material/Paper';
import $ from 'jquery';
import Checkbox from '@mui/material/Checkbox';

import SmsIcon from '@mui/icons-material/Sms';
import Link from '@mui/material/Link';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DialogContentText from '@mui/material/DialogContentText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import Swal from 'sweetalert2'
import ButtonGroup from '@mui/material/ButtonGroup';

import withReactContent from 'sweetalert2-react-content'
import MailIcon from '@mui/icons-material/Mail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import CodeIcon from '@mui/icons-material/Code';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PauseIcon from '@mui/icons-material/Pause';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DeleteIcon from '@mui/icons-material/Delete';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LoginIcon from '@mui/icons-material/Login';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import KeyIcon from '@mui/icons-material/Key';
import { blue } from '@mui/material/colors';
import { DialogContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import PixIcon from '@mui/icons-material/Pix';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloudIcon from '@mui/icons-material/Cloud';
import { io } from "socket.io-client";
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import StoreIcon from '@mui/icons-material/Store';
import { config } from 'bluebird';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
moment.locale('pt-br');


const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
})

const socket = io("wss://socket.centralda20.com");

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


if (!localStorage.session) {
  localStorage.session = JSON.stringify([]);
} else {
  localStorage.session = JSON.stringify(Array.from(new Set(JSON.parse(localStorage.session).map(JSON.stringify))).map(JSON.parse));
}

const defaultTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        /* Estilo global para scrollbars */
        ::-webkit-scrollbar {
          width: 8px; /* Largura do scrollbar vertical */
          height: 8px; /* Altura do scrollbar horizontal */
        }
        .swal2-container {
          z-index: 300000;
        }
        ::-webkit-scrollbar-track {
        border-radius: 3px;
          background: #f1f1f1; /* Cor do trilho do scrollbar */
        }

        ::-webkit-scrollbar-thumb {
          background: #888; /* Cor da "mão" do scrollbar */
          border-radius: 3px; /* Borda arredondada da "mão" */
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555; /* Cor da "mão" ao passar o mouse */
        }

        /* Estilo específico para scrollbars horizontais */
        ::-webkit-scrollbar-horizontal {
          height: 8px; /* Altura do scrollbar horizontal */
        }
      `,
    },
  },
  palette: {
    mode: 'dark'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const sleep = ms => new Promise(r => setTimeout(r, ms));

function downloadString(text, fileType, fileName) {
  var blob = new Blob([text], { type: fileType });

  var a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
}

function formatPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3 $4');
}

var currentValuePix = 50;
var currentValueCrypto = 50;
var listaEmailsSeparadorOut = [];
var totalListaEmailsOut = 0;
var currentEmailsOut = 0;
var progressoEmailsOut = 0;
var validasEmailsOut = 0;

function App() {
  document.title = "Central Da20";
  var livesGerador = 10;
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openMinhasInstancias, setOpenMinhasInstancias] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(true);
  const [listaGeradoresCriados, setListaGeradoresCriados] = React.useState([]);
  const [listaGeradores, setListaGeradores] = React.useState([]);
  const [listaRecargas, setListaRecargas] = React.useState([]);
  const [listaInstancias, setListaInstancias] = React.useState([]);
  const [servicesSMS, setServicesSMS] = React.useState([]);
  const [cloudRows, setCloudRows] = React.useState([]);
  const [listaInstanciasSearch, setListaInstanciasSearch] = React.useState([]);
  const [openCriarInstancia, setOpenCriarInstancia] = React.useState(false);
  const [openInserirLista, setOpenInserirLista] = React.useState(false);
  const [openCriarGerador, setOpenCriarGerador] = React.useState(false);
  const [openRecargas, setOpenRecargas] = React.useState(false);
  const [openConsultas, setOpenConsultas] = React.useState(false);
  const [limiteLinhas, setLimiteLinhas] = React.useState(10000);
  const [limiteInstancias, setLimiteInstancias] = React.useState(false);
  const [showGeradorInfo, setShowGeradorInfo] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [valoresAdicionais, setValoresAdicionais] = React.useState({});
  const [disabledAdicionais, setDisabledAdicionais] = React.useState({});
  const [valueUserRegister, setValueUserRegister] = React.useState("");
  const [valueLista, setValueLista] = React.useState("");
  const [infoGeradores, setInfoGeradores] = React.useState("");
  const [inputSearchCloud, setInputSearchCloud] = React.useState("");
  const [retornoConsulta, setRetornoConsulta] = React.useState("");
  const [tipoConsulta, setTipoConsulta] = React.useState(false);
  const [valorConsulta, setValorConsulta] = React.useState(false);
  const [cloudLoading, setCloudLoading] = React.useState(false);
  const [livesGeradorLista, setLivesGeradorLista] = React.useState("");
  const [livesInstancia, setLivesInstancia] = React.useState("");
  const [diesInstancia, setDiesInstancia] = React.useState("");
  const [resultEmails, setResultEmails] = React.useState("");
  const [valorPorResultado, setValorPorResultado] = React.useState(0);
  const [loginsTotal, setLoginsTotal] = React.useState(0);
  const [cloudPageSize, setCloudPageSize] = React.useState(0);
  const [rowCountCloud, setRowCountCloud] = React.useState(0);
  const [emailsCarregados, setEmailsCarregados] = React.useState(0);
  const [progressoEmails, setProgressoEmails] = React.useState(0);
  const [inputKey, setInputKey] = React.useState(0);
  const [inputListKey, setInputListKey] = React.useState(0);

  const [emailsValidos, setEmailsValidos] = React.useState(0);
  const [currentServiceSMS, setCurrentServiceSMS] = React.useState(false);
  const [openResultsInstancia, setOpenResultsInstancia] = React.useState(false);
  const [openResultsGerador, setOpenResultsGerador] = React.useState(false);
  const [OpenSeparadorEmail, setOpenSeparadorEmail] = React.useState(false);
  const [labelLista, setLabelLista] = React.useState("cole a lista abaixo ou envie um arquivo TXT");
  const [boostInfo, setBoostInfo] = React.useState(<><CloseIcon color="error" /> &nbsp;Otimização desativada</>);
  const [labelUploadLista, setLabelUploadLista] = React.useState("selecionar arquivo");
  const [labelDiesList, setLabelDiesList] = React.useState("selecionar arquivo");
  const [labelLivesList, setLabelLivesList] = React.useState("selecionar arquivo");
  const [labelLivesListGerador, setLabelLivesListGerador] = React.useState("selecionar arquivo");
  const [disabledUploadButton, setDisabledUploadButton] = React.useState(false);
  const [openCloud, setOpenCloud] = React.useState(false);
  const [openAPI, setOpenAPI] = React.useState(false);
  const [openAdicionais, setOpenAdicionais] = React.useState(false);
  const [openSms, setOpenSms] = React.useState(false);
  const [usersOnline, setUsersOnline] = React.useState(<CircularProgress color="secondary" />);

  const [displayLoginError, setDisplayLoginError] = React.useState("none");
  const [saldo, setSaldo] = React.useState(<CircularProgress color="secondary" />);
  const [usuario, setUsuario] = React.useState(<CircularProgress color="secondary" />);
  const [totalLives, setTotalLives] = React.useState(<CircularProgress color="secondary" />);
  const [instanciasCriadas, setInstanciasCriadas] = React.useState(<CircularProgress color="secondary" />);
  const [ultimoAcesso, setUltimoAcesso] = React.useState(<CircularProgress color="secondary" />);
  const [online, setOnline] = React.useState(<CircularProgress color="secondary" />);
  const [listaInstanciasAndamento, setListaInstanciasAndamento] = React.useState([]);
  const [numerosSmsList, setNumerosSmsList] = React.useState([]);
  const [listaInstanciasFinalizadas, setListaInstanciasFinalizadas] = React.useState([]);
  const [instanciaSelecionada, setInstanciaSelecionada] = React.useState(false);
  const [geradorSelecionado, setGeradorSelecionado] = React.useState(false);
  const [runningSeparador, setRunningSeparador] = React.useState(false);
  const [separadorPausado, setSeparadorPausado] = React.useState(false);
  const [cloudCurrentPage, setCloudCurrentPage] = React.useState(0);
  const [paginationModelCloud, setPaginationModelCloud] = React.useState({
    pageSize: 20,
    page: 0,
  });

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(["ibest.com.br", "terra.com.br", "gmail.com", "outlook.com.br", "outlook.com", "msn.com", "hotmail.com", "hotmail.com.br", "live.com", "bol.com.br", "uol.com.br", "yahoo.com", "yahoo.com.br", "ig.com.br", "icloud.com"]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 500, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              disabled={runningSeparador}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disabled={runningSeparador}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  var RemoveKeyFromSessions = (key) => {
    var sessions = JSON.parse(localStorage.session);
    sessions = sessions.filter(ss => ss.key !== key);
    sessions = Array.from(new Set(sessions.map(JSON.stringify))).map(JSON.parse);
    localStorage.session = JSON.stringify(sessions);
    window.location.reload()
  }

  var showKey = (key) => {
    Swal.fire({
      title: "👀",
      text: key,
      footer: "aqui está sua chave de acesso"
    })
  }

  const toggleDrawer = (newOpen) => () => {
    setOpenMenu(newOpen);
  };

  const toggleMinhasInstancias = (newOpen) => () => {
    setOpenMinhasInstancias(newOpen);
  };

  const toggleNovaInstancia = (newOpen) => () => {
    if (newOpen) {
      socket.emit("getModulos");
    } else {
      setOpenCriarInstancia(false);
    }
  };

  socket.on("modulos", (result) => {

    var instancias_list = result.map(l => {
      var new_l = {};
      new_l.id = l._id;
      new_l.modulo = l.nome;
      new_l.info = l.info;
      new_l.last_live = moment(l.last_live).format('DD/MM/YYYY HH:mm');
      new_l.valor_live = l.live;
      new_l.valor_die = l.die;
      new_l.params = l.params;
      return new_l;
    });

    setListaInstancias(instancias_list);
    setListaInstanciasSearch(instancias_list);

    setOpenCriarInstancia(true);
  })

  var toggleSMS = (newOpen) => {
    if (newOpen) {
      socket.emit("getServicesSMS");
      setLoading(true);
    } else {
      setOpenSms(newOpen);
    }
  }

  socket.on("getServicesSMS", result => {
    setLoading(false);
    setOpenSms(true);
    setServicesSMS(result);
  });

  var selectServiceSMS = (service) => {
    setCurrentServiceSMS(service)
    setLoading(true);
    setOpenSms(false);

    socket.emit("checkPriceSMS", service);
  }

  socket.on("checkPriceSMS", async (result) => {
    setLoading(false);
    setOpenSms(false);

    if (result.error) {
      return Swal.fire({
        icon: "warning",
        text: result.message,
        willClose: () => setOpenSms(true),
      });
    }
    setOpenSms(false)
    var resultSwal = await Swal.fire({
      icon: "warning",
      willOpen: () => setOpenSms(false),
      title: "Preço Atual",
      text: `O preço atual para utilização do serviço ${result.nome} está em R$ ${result.valor}`,
      footer: "Deseja continuar?",
      showDenyButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "Gerar Número",
      denyButtonText: "Cancelar"
    });

    if (resultSwal.isConfirmed) {
      setLoading(true);
      setOpenSms(false);

      socket.emit("gerarNumero", {id: result.id});
    } else {
      setOpenSms(true);
    }
  });

  socket.on("gerarNumero", (result) => {
    setLoading(false);
    if (result.error) {
      
      return Swal.fire({
        icon: "warning",
        text: result.message,
      });
    }

    return Swal.fire({
      icon: "success",
      title: formatPhoneNumber(result.number),
      text: `Número gerado com sucesso `,
      willClose: () => setOpenSms(true)
    });

  });

  const toggleInserirLista = (newOpen) => () => {
    setOpenInserirLista(newOpen);
  };
  const toggleCriarGerador = (newOpen) => () => {
    if (newOpen) {
      socket.emit("getGeradores");
    } else {
      toggleCriarGerador(false);
    }
  };

  socket.on("geradores", (result) => {
    var geradores_list = result.map(l => {
      var new_l = {};
      new_l.id = l._id;
      new_l.modulo = l.nome;
      new_l.info = l.info;
      new_l.last_live = moment(l.last_live).format('DD/MM/YYYY HH:mm');
      new_l.valor_live = l.live;
      new_l.params = l.params;
      return new_l;
    });

    setListaGeradores(geradores_list);
    setShowGeradorInfo(false);
    setOpenCriarGerador(true);
  });

  const toggleRecargas = (newOpen) => () => {
    socket.emit("getRecargas");
    setLoading(true);

  };

  socket.on("getRecargas", (result) => {

    var NewlistaRecargas = result.map(l => {
      console.log(l)
      if (l.pago) {
        l.status = "PAGO"
      } else if (l.expirado) {
        l.status = "EXPIRADO"
      } else {
        l.status = "PENDENTE"
      }

      //l.data_criado = moment(l.data_criado).format('DD/MM/YYYY HH:mm');
      //l.data_expira = moment(l.data_expira).format('DD/MM/YYYY HH:mm');
      l.id = l._id;
      return l
    });

    setListaRecargas(NewlistaRecargas);

    setLoading(false);
    setOpenRecargas(true);
  })

  const toggleConsultas = (newOpen) => () => {
    setOpenConsultas(newOpen);
  };
  const toggleDataCloud = (newOpen) => () => {
    setOpenCloud(newOpen);
  };
  const toggleResults = (newOpen) => () => {
    setOpenResultsInstancia(newOpen);
  };

  var Autenticar = (key) => {
    setOpenLogin(false);
    setLoading(true);


    socket.emit("auth", key);
  }

  socket.on("auth", (result) => {
    if (result.error) {
      setDisplayLoginError("inline");
      setTimeout(() => {
        setOpenLogin(true);
        setLoading(false);
      }, 2000);
    }
  });

  var GerarPix = () => {
    setLoading(true);
    socket.emit("GerarPix", Number(currentValuePix));
  }

  socket.on("registerUser", (result) => {
    if (result.error) {
      Swal.fire({
        icon: "error",
        text: result.message
      });
    } else {
      Swal.fire({
        icon: "success",
        text: result.message
      });
      Autenticar(result.key);
    }
    setLoading(false);
  })

  socket.on("GerarPix", (result) => {
    setLoading(false);
    if (result.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Já existe um pagamento em aberto, acesse Minhas Recargas e realize o pagamento",
      });
    } else {
      Swal.fire({
        title: "PIX",
        text: "Expira em 10 minutos",
        imageUrl: `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(result.copia_cola)}`,
        imageWidth: 150,
        imageHeight: 150,
        allowOutsideClick: false,
        showCancelButton: true,
        allowEscapeKey: false,
        cancelButtonText: "Fechar",
        showConfirmButton: false
      });
    }

  });

  socket.on("ping", (result) => {
    setLimiteLinhas(result.limite_linhas);
    setOnline(result.online);
    setSaldo(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result.saldo));
    setTotalLives(result.lives);
    setUltimoAcesso(moment(result.last_login).format('DD/MM/YYYY HH:mm'));
    setUsuario(result.user);
    setInstanciasCriadas(result.instancias);
    setUsersOnline(result.usersOnline);
    setLimiteInstancias(result.limite_instancias);
    setDisabledAdicionais(result.disabledAdicionais);
    setValoresAdicionais(result.valoresAdicionais);
    setLoginsTotal(result.logins_total);
    setValorPorResultado(result.valor_por_resultado);
    setCloudPageSize(result.cloud_page_size);
    setNumerosSmsList(result.numeros_sms);
    var geradores = result.geradoresLista.map(l => {
      var new_l = {};
      new_l.id = l._id;
      new_l.nome = l.nome;
      new_l.lives = l.lives;
      new_l.total = l.total;
      new_l.progresso = l.progresso;
      new_l.finalizado = l.finalizado;
      new_l.data_criado = l.data_criado;
      new_l.data_finalizado = l.data_finalizado;
      return new_l;
    })

    setListaGeradoresCriados(geradores);

    if (result.boost > 1) {
      setBoostInfo(<><CheckIcon color="success" /> &nbsp;Otimização Ativada</>);
    } else {
      setBoostInfo(<><CloseIcon color="error" /> &nbsp;Otimização desativada</>)
    }

    //{ id: 1, modulo: 'Snow', lives: 0, finalizado: false, total: 1000, andamento: 89.34 }

    var instanciasEmAndamento = result.instanciasLista.filter(i => i.andamento == true).map(l => {
      var new_l = {};
      new_l.id = l._id;
      new_l.modulo = l.modulo;
      new_l.lives = l.lives;
      new_l.dies = l.dies;
      new_l.total = l.total;
      new_l.progresso = l.progresso;
      new_l.andamento = l.andamento;
      new_l.finalizado = l.finalizado;
      new_l.data_criado = l.data_criado;
      new_l.data_finalizado = l.data_finalizado;
      return new_l;
    });

    var instanciasFinalizadas = result.instanciasLista.filter(i => i.finalizado == true).map(l => {
      var new_l = {};
      new_l.id = l._id;
      new_l.modulo = l.modulo;
      new_l.lives = l.lives;
      new_l.dies = l.dies;
      new_l.total = l.total;
      new_l.progresso = l.progresso;
      new_l.andamento = l.andamento;
      new_l.finalizado = l.finalizado;
      new_l.data_criado = l.data_criado;
      new_l.data_finalizado = l.data_finalizado;
      return new_l;
    });

    setListaInstanciasAndamento(instanciasEmAndamento);
    setListaInstanciasFinalizadas(instanciasFinalizadas);

    if (result.firstPing) {
      setLoading(false);
      var sessions = JSON.parse(localStorage.session);
      sessions.push({ key: result.key, user: result.user });
      sessions = Array.from(new Set(sessions.map(JSON.stringify))).map(JSON.parse);
      localStorage.session = JSON.stringify(sessions);
    }
  })

  var filtrarPesquisa = (params) => {
    if (params.target.value.length == 0) {
      setListaInstancias(listaInstanciasSearch)
    } else {
      var resultFiltro = listaInstanciasSearch.filter(l => l.modulo.toLowerCase().includes(params.target.value.toLowerCase()));
      setListaInstancias(resultFiltro)
    }

  }

  const handleFileChange = (event) => {
    setInputListKey(prevKey => prevKey + 1);

    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        var lista = [...new Set(fileReader.result.split("\n"))].filter(n => n);
        lista = lista.slice(0, limiteLinhas);
        setLabelLista(`${lista.length}/${limiteLinhas} linhas inseridas`);
        setValueLista(lista.join("\n"));
      };
      fileReader.readAsText(file);
    }
  };

  var threadSeparador = async () => {

    if (listaEmailsSeparadorOut.length == 0) {
      setRunningSeparador(false);
      return;
    }

    var linha = listaEmailsSeparadorOut.shift();

    var valid = false;
    currentEmailsOut++;
    right.forEach(domain => {
      if (linha.includes(`@${domain}`)) {
        valid = true;
      }
    });
    if (valid) {
      validasEmailsOut.push(linha);
    }
    progressoEmailsOut = ((currentEmailsOut * 100) / totalListaEmailsOut).toFixed(2);

    setResultEmails(validasEmailsOut.slice(0, 1000).join("\n"));
    setEmailsValidos(validasEmailsOut.length);
    setProgressoEmails(progressoEmailsOut);

    await sleep(25);

    threadSeparador()
  }

  const handleFileChangeSeparador = (event) => {
    setInputKey(prevKey => prevKey + 1);
    setResultEmails("");
    setEmailsValidos(0);
    setProgressoEmails(0);
    setSeparadorPausado(false);
    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setRunningSeparador(true);
        var lista = [...new Set(fileReader.result.split("\n"))].filter(n => n);
        if (lista.length > 100000) {
          lista = lista.slice(0, 100000);
          setEmailsCarregados(`100000 (LIMITE MÁXIMO)`)
        } else {
          setEmailsCarregados(lista.length)
        }

        listaEmailsSeparadorOut = lista;
        currentEmailsOut = 0;
        totalListaEmailsOut = lista.length;
        progressoEmailsOut = 0;
        validasEmailsOut = [];
        
        for (let index = 1; index <= 8; index++) {
          threadSeparador();
        }
      };
      fileReader.readAsText(file);
    }
  };

  const criarInstancia = () => {
    if (instanciaSelecionada.params.length !== 0) {
      setOpenInserirLista(false);
      withReactContent(Swal).fire({
        title: "Configurações da instância",
        html: <>{instanciaSelecionada.params.map(param => {
          return (<><TextField fullWidth maxWidth="lg" label={param.info} variant="standard" onChange={(params) => {
            instanciaSelecionada.params.map(l => {
              if (l.id == param.id) {
                l.value = params.target.value;
              }
            });
          }} /><br /></>)
        })}</>,
        confirmButtonText: "Criar",
        width: "50%",
        showCancelButton: true,
        cancelButtonText: "Fechar",
      }).then((result) => {
        if(!result.isConfirmed){return}
        socket.emit("criarInstancia", { lista: valueLista, id: instanciaSelecionada.id, params: instanciaSelecionada.params });
        setLoading(true);
      });
    } else {
      setOpenInserirLista(false);
      socket.emit("criarInstancia", { lista: valueLista, id: instanciaSelecionada.id, params: instanciaSelecionada.params });
      setLoading(true);
    }
  };

  socket.on("instanciaCriada", (result) => {
    if (result.error) {
      Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      Swal.fire({
        icon: "success",
        text: result.message
      })
    }
    setLoading(false);
  })

  var fetchResultsGerador = (id) => {
    setLoading(true);
    socket.emit("fetchResultsGerador", id);
  }

  socket.on("fetchResultsGerador", (result) => {
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    }

    setLivesGeradorLista(result.lives);

    setLabelLivesListGerador(`${result.total_lives} LIVES`);

    setOpenResultsGerador(true);

    setLoading(false);
  });

  var fetchResults = (id) => {
    setLoading(true);
    socket.emit("fetchResults", id);
  }

  socket.on("fetchResults", (result) => {
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    }
    setLivesInstancia(result.lives);
    setDiesInstancia(result.dies);

    setLabelLivesList(`${result.total_lives} LIVES`);
    setLabelDiesList(`${result.total_dies} DIES`);

    setOpenResultsInstancia(true);

    setLoading(false);
  });

  var pauseInstance = (id) => {
    setOpenMinhasInstancias(false);
    Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "deseja pausar a instância",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "pausar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        socket.emit("pauseInstance", id);
      }
    })
  }

  var pauseGerador = (id) => {
    Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "deseja pausar o gerador?",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "pausar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        socket.emit("pauseGerador", id);
      } else {
        setLoading(true);
      }
    })
  }

  var deleteInstance = (id) => {
    setOpenMinhasInstancias(false);
    Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "deseja deletar a instância",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "deletar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        socket.emit("deleteInstance", id);
      } else {
        setLoading(true);
        setOpenMinhasInstancias(true);
      }
    })
  }

  var deleteGerador = (id) => {
    Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "deseja deletar o gerador?",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "deletar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        socket.emit("deleteGerador", id);
      } else {
        setLoading(true);
      }
    })
  }



  socket.on("deleteGerador", (result) => {
    setLoading(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  });

  socket.on("pauseInstance", (result) => {
    setLoading(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  });

  socket.on("pauseGerador", (result) => {
    setLoading(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  });

  socket.on("deleteInstance", (result) => {
    setLoading(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  });

  var buyAdicional = (id) => {
    setOpenAdicionais(false);
    Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "deseja realizar esta compra?",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "comprar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setOpenAdicionais(true);
        socket.emit("buyAdicional", id);
      } else {

        setOpenAdicionais(true);
      }
    });
  }

  socket.on("buyAdicional", (result) => {
    setLoading(false);
    setOpenAdicionais(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  });

  var replayInstance = async (id) => {
    setOpenMinhasInstancias(false);

    var configInstancia = {};

    var result = await Swal.fire({
      icon: 'warning',
      title: "confirme",
      title: "configure sua instância",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: "cancelar",
      confirmButtonText: "reiniciar do início",
      cancelButtonText: "continuar de onde parou"
    });

    if (result.isDenied) { return setOpenMinhasInstancias(true) };

    configInstancia.inicio = result.isConfirmed;

    var result = await Swal.fire({
      icon: 'warning',
      title: "confirme",
      title: "configure sua instância",
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: "cancelar",
      confirmButtonText: "manter instância original",
      cancelButtonText: "deletar instância original"
    });

    if (result.isDenied) { return setOpenMinhasInstancias(true) };

    configInstancia.manter = result.isConfirmed;

    socket.emit("replayInstance", { id: id, config: configInstancia });

    setLoading(true);
    //setOpenMinhasInstancias(true);
  }

  socket.on("replayInstance", (result) => {
    setLoading(false);
    setOpenMinhasInstancias(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  })

  var iniciarGerador = async () => {
    setOpenCriarGerador(false);
    var result = await Swal.fire({
      icon: 'warning',
      title: "confirme",
      text: "o gerador não é necessário lista, o limite de lives você escolhe ou até seu saldo acabar antes.",
      footer: `módulo selecionado: ${geradorSelecionado.modulo} | Custo Máximo: R$ ${(livesGerador * geradorSelecionado.valor_live).toFixed(2)}`,
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: false,
      confirmButtonText: "iniciar gerador",
      cancelButtonText: "cancelar"
    });

    if (result.isConfirmed) {
      if (geradorSelecionado.params.length != 0) {
        await withReactContent(Swal).fire({
          title: "Configurações do Gerador",
          html: <>{geradorSelecionado.params.map(param => {
            return (<><TextField label={param.info} variant="standard" onChange={(params) => {
              geradorSelecionado.params.map(l => {
                if (l.id == param.id) {
                  l.value = params.target.value;
                }
              });
            }} /><br /></>)
          })}</>,
          confirmButtonText: "Criar",
          width: "50%",
          showCancelButton: true,
          cancelButtonText: "Fechar",
        })
      }


      setLoading(true);
      socket.emit("iniciarGerador", { gerador: geradorSelecionado.id, lives: livesGerador, params: geradorSelecionado.params });
    } else {
      setOpenCriarGerador(true);
    }
  }

  socket.on("disconnect", () => {
    window.location.reload();
  })

  socket.on("iniciarGerador", (result) => {
    setLoading(false);
    setOpenCriarGerador(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      return Swal.fire({
        icon: "success",
        text: result.message
      })
    }
  })

  var consultaStart = () => {
    setOpenConsultas(false);
    setLoading(true);
    socket.emit("consultaStart", { tipo: tipoConsulta, valor: valorConsulta });
  };

  socket.on("consultaStart", (result) => {
    setLoading(false);
    if (result.error) {
      return Swal.fire({
        icon: "error",
        text: result.message
      })
    } else {
      setOpenConsultas(true);
      setRetornoConsulta(result.response);

    }
  })

  var searchCloud = () => {
    setCloudLoading(true);
    setPaginationModelCloud({
      pageSize: 20,
      page: 0,
    })
    socket.emit("searchCloud", { input: inputSearchCloud, page: cloudCurrentPage });
  }

  socket.on("searchCloud", (result) => {
    if (result.error) {
      setOpenCloud(false);
      return Swal.fire({
        icon: "error",
        text: result.message
      }).then(() => {
        setOpenCloud(true);
      });
    }

    setCloudRows(result.rows);
    setRowCountCloud(result.total);
    setCloudLoading(false);
  });

  var searchCloudWithPage = (page) => {
    setPaginationModelCloud(page);
    setCloudLoading(true);
    socket.emit("searchCloud", { input: inputSearchCloud, page: page.page });
  }

  const PulsatingIcon = styled(MenuIcon)(({ theme }) => ({
    fontSize: '2rem', // Define o tamanho do ícone
    animation: 'pulse 2s ease-in 0s infinite reverse none', // Aplica a animação de pulsação
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '40%': {
        transform: 'scale(1.40)',
        opacity: 1,
      },
    },
  }));

  var GerarCrypto = () => {
    setLoading(true);
    socket.emit("GerarCrypto", Number(currentValueCrypto));
  }

  socket.on("GerarCrypto", (result) => {
    setLoading(false);
    if (result.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Já existe um pagamento em aberto, acesse Minhas Recargas e realize o pagamento",
      });
    } else {
      window.open(result.invoice_url, '_blank')
      Swal.fire({
        title: "Crypto",
        text: "Um link será aberto para realizar o pagamento",
        allowOutsideClick: false,
        showCancelButton: true,
        allowEscapeKey: false,
        cancelButtonText: "Fechar",
        showConfirmButton: true,
        confirmButtonText: "Abrir Link",
        preConfirm: () => window.open(result.invoice_url, '_blank')
      });
    }

  });

  const DrawerList = (
    <Box sx={{ width: 270 }} role="presentation" onClick={toggleDrawer(false)}>


      <List>
        <ListItem key={"Recarregar via PIX"} disablePadding>
          <ListItemButton onClick={() => {
            withReactContent(Swal).fire({
              title: "Selecione o valor",
              html: <><br /><Slider
                defaultValue={50}
                valueLabelDisplay="on"
                shiftStep={10}
                valueLabelFormat={(value) => {
                  return `R$ ${value}`
                }}
                onChange={(params) => {
                  currentValuePix = params.target.value;

                }}
                step={10}
                marks
                sx={{ width: "95%", float: "center" }}
                min={50}
                max={250}
              /><br /></>,
              confirmButtonText: "Pagar",
              width: "50%",
              showCancelButton: true,
              cancelButtonText: "Fechar",
            }).then((result) => {
              if (result.isConfirmed) {
                GerarPix();
              }
            });
          }}>
            <ListItemIcon> <PixIcon /> </ListItemIcon>
            <ListItemText primary={"Recarregar via PIX"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Recarregar via CRYPTO"} disablePadding>
          <ListItemButton onClick={() => {
            withReactContent(Swal).fire({
              title: "Selecione o valor",
              html: <><br /><Slider
                defaultValue={50}
                valueLabelDisplay="on"
                shiftStep={10}
                valueLabelFormat={(value) => {
                  return `R$ ${value}`
                }}
                onChange={(params) => {
                  currentValueCrypto = params.target.value;

                }}
                step={10}
                marks
                sx={{ width: "95%", float: "center" }}
                min={50}
                max={250}
              /><br /></>,
              confirmButtonText: "Pagar",
              width: "50%",
              showCancelButton: true,
              cancelButtonText: "Fechar",
            }).then((result) => {
              if (result.isConfirmed) {
                GerarCrypto();
              }
            });
          }}>
            <ListItemIcon> <CurrencyBitcoinIcon /> </ListItemIcon>
            <ListItemText primary={"Recarregar via CRYPTO"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Adicionais"} disablePadding>
          <ListItemButton onClick={() => setOpenAdicionais(true)}>
            <ListItemIcon> <AddIcon /> </ListItemIcon>
            <ListItemText primary={"Adicionais"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem key={"Minhas Instâncias"} disablePadding>
          <ListItemButton onClick={toggleMinhasInstancias(true)}>
            <ListItemIcon> <CloudDoneIcon /> </ListItemIcon>
            <ListItemText primary={"Minhas Instâncias"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Minhas Recargas"} disablePadding>
          <ListItemButton onClick={toggleRecargas(true)}>
            <ListItemIcon> <AccountBalanceWalletIcon /> </ListItemIcon>
            <ListItemText primary={"Minhas Recargas"} />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem key={"Geradores"} disablePadding>
          <ListItemButton onClick={toggleCriarGerador(true)}>
            <ListItemIcon> <FormatLineSpacingIcon /> </ListItemIcon>
            <ListItemText primary={"Geradores"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Consultas"} disablePadding>
          <ListItemButton onClick={toggleConsultas(true)}>
            <ListItemIcon> <PersonSearchIcon /> </ListItemIcon>
            <ListItemText primary={"Consultas"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Data Cloud"} disablePadding>
          <ListItemButton onClick={toggleDataCloud(true)}>
            <ListItemIcon> <CloudIcon /> </ListItemIcon>
            <ListItemText primary={"Data Cloud"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"SMS Receiver"} disablePadding>
          <ListItemButton onClick={() => toggleSMS(true)}>
            <ListItemIcon> <SmsIcon /> </ListItemIcon>
            <ListItemText primary={"SMS Receiver"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Separador de E-mail"} disablePadding>
          <ListItemButton onClick={() => setOpenSeparadorEmail(true)}>
            <ListItemIcon> <EmailIcon /> </ListItemIcon>
            <ListItemText primary={"Separador de E-mail"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const StyledButton = styled(Button)(({ theme }) => ({
  }));

  const handleDragStart = (event) => {
    // Torna o fantasma invisível
    event.dataTransfer.setDragImage(new Image(), 0, 0);
    document.body.style.cursor = 'auto'; // Restaura o cursor padrão

  };
  const handleDragEnd = () => {
    // Restaura o cursor padrão quando o arrasto termina
    document.body.style.cursor = 'auto'; // Restaura o cursor padrão
  };

  var deleteSMS = (id) => {
    setLoading(true);
    socket.emit("deleteSMS", id);
  }

  var cancelSMS = (id) => {
    setLoading(true);
    socket.emit("cancelSMS", id);

  }

  socket.on("cancelSMS", (result) => {
    setLoading(false);
    if (result.error) {
      
      return Swal.fire({
        icon: "warning",
        text: result.message,
      });
    }

    return Swal.fire({
      icon: "success",
      text: result.message
    });

  });

  socket.on("deleteSMS", (result) => {
    setLoading(false);
    if (result.error) {
      
      return Swal.fire({
        icon: "warning",
        text: result.message,
      });
    }

    return Swal.fire({
      icon: "success",
      text: result.message
    });

  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Backdrop
        sx={{ bgcolor: 'black', zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={Loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ display: 'flex' }} >
        <CssBaseline />

        <StyledButton onDragEnd={handleDragEnd} onDragStart={handleDragStart} draggable onClick={toggleDrawer(true)} onDrag={toggleDrawer(true)}><PulsatingIcon /></StyledButton>

        <Drawer open={openMenu} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={false}>
            <Grid container spacing={3}>

              <Grid item >

                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Bem-vindo
                    </Typography>
                    <Typography variant="h5">
                      {usuario}
                    </Typography>

                  </CardContent>

                </Card>
              </Grid><Grid item >

                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Saldo
                    </Typography>
                    <Typography variant="h5">
                      {saldo}
                    </Typography>

                  </CardContent>

                </Card>
              </Grid><Grid item >
                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Total Lives
                    </Typography>
                    <Typography variant="h5">
                      {totalLives}
                    </Typography>

                  </CardContent>
                </Card>
              </Grid><Grid item >
                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Instâncias Criadas
                    </Typography>
                    <Typography variant="h5">
                      {instanciasCriadas}
                    </Typography>

                  </CardContent>
                </Card>
              </Grid><Grid item >
                <Tooltip title={usersOnline}>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Usuários Online
                      </Typography>
                      <Typography variant="h5">
                        {online}
                      </Typography>

                    </CardContent>
                  </Card> </Tooltip>
              </Grid>
              <Grid item >
                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Último Acesso
                    </Typography>
                    <Typography variant="h5">
                      {ultimoAcesso}
                    </Typography>

                  </CardContent>
                </Card>

              </Grid>



              <Grid item xs={12}>
                <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Box sx={{ float: "right" }}>
                      <Button sx={{ color: 'white' }} color="success" onClick={toggleMinhasInstancias(true)}>
                        Minhas Instâncias
                      </Button>
                      &nbsp;&nbsp;
                      <Button sx={{ color: 'white' }} onClick={toggleNovaInstancia(true)}>
                        Criar nova Instância
                      </Button>
                    </Box>

                    <Typography variant='h6'>
                      Instâncias em andamento
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Seu limite atual é {limiteInstancias} instâncias de {limiteLinhas} linhas. Faça um upgrade <Link href="#" onClick={() => setOpenAdicionais(true)}>clicando aqui.</Link>
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} variant="subtitle1" gutterBottom>
                      {boostInfo}
                    </Typography>
                    <br />
                    <DataGrid
                      localeText={{ noRowsLabel: "Nenhuma instância em andamento", noResultsOverlayLabel: "Nenhuma instância em andamento" }}
                      rows={listaInstanciasAndamento}

                      columns={[{
                        field: 'modulo',
                        headerName: 'Módulo',

                        flex: true, sortable: false,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {

                          return <><Alert
                            severity="primary"
                            icon={<CircularProgress size="1rem" color="success" />}
                          >
                            {params.row.modulo}
                          </Alert></>

                        }
                      }, {
                        field: 'info',
                        headerName: 'Informações',
                        flex: true,
                        sortable: false,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {
                          return <><Alert
                            severity="primary"
                            icon={false}
                          >
                            Em andamento - {params.row.progresso}%
                          </Alert></>
                        }
                      }, {
                        field: 'lives',
                        headerName: 'Lives',
                        sortable: false,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {

                          return <><Alert
                            severity="primary"
                            icon={false}
                          >
                            {params.row.lives}
                          </Alert></>
                        }
                      }, {
                        field: 'dies',
                        headerName: 'Dies',
                        sortable: false,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {

                          if (!params.row.finalizado) {
                            return <><Alert
                              severity="primary"
                              icon={false}
                            >
                              {params.row.dies}
                            </Alert></>
                          } else {
                            return <><Alert
                              severity="warning"
                              icon={false}
                            >
                              {params.row.dies}
                            </Alert></>
                          }
                        }
                      }, {
                        field: 'total',
                        headerName: 'Total',
                        sortable: false,
                        flex: true,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {
                          return <><Alert
                            severity="primary"
                            icon={false}
                          >
                            {params.row.lives + params.row.dies}/{params.row.total}
                          </Alert></>
                        }
                      }, {
                        field: 'criacao',
                        headerName: 'Data de criação',
                        sortable: false,
                        filterable: false,
                        flex: true,
                        disableColumnMenu: true,
                        renderCell: (params) => {
                          var sev = "primary";
                          var txt = `${moment(params.row.data_criado).format('DD/MM/YYYY HH:mm:ss')}`;
                          if (params.row.finalizado) {
                            sev = "warning";
                            txt = `${moment(params.row.data_criado).format('DD/MM/YYYY HH:mm:ss')} --- ${moment(params.row.data_finalizado).format('DD/MM/YYYY HH:mm:ss')}`;
                          }

                          return <><Alert
                            severity={sev}
                            icon={false}
                          >
                            {txt}
                          </Alert></>
                        }
                      }, {
                        field: 'acoes',
                        headerName: 'Gerenciar',
                        flex: true,
                        sortable: false,
                        filterable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => {

                          return (<>
                            <Button onClick={() => fetchResults(params.row.id)} variant="contained" color="success" size="small" sx={{ color: "white" }}><ChecklistIcon /></Button>&nbsp;
                            <Button onClick={() => pauseInstance(params.row.id)} variant="contained" color="warning" size="small" sx={{ color: "white" }}><PauseIcon /></Button>&nbsp;
                            <Button onClick={() => deleteInstance(params.row.id)} variant="contained" color="error" size="small"><DeleteIcon /></Button>
                          </>);

                        }

                      }]}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      disableRowSelectionOnClick
                      hideFooter
                    />
                  </CardContent>

                </Card>

              </Grid>

              <Grid item xs={12}>
                <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Button sx={{ float: "right", color: "white" }} onClick={toggleCriarGerador(true)}>
                      Inicie um gerador
                    </Button>
                    <Typography variant='h6'>
                      Geradores em andamento
                    </Typography>
                    <br />
                    <Box sx={{ width: '100%' }}>
                      <DataGrid

                        localeText={{ noRowsLabel: "Nenhum gerador em andamento" }}
                        rows={listaGeradoresCriados}
                        columns={[{
                          field: 'nome',
                          headerName: 'Módulo',
                          flex: true, sortable: false,
                          filterable: false,
                          disableColumnMenu: true,
                          renderCell: (params) => {
                            if (params.row.finalizado) {
                              return <><Alert
                                severity="warning"
                                icon={<PauseIcon size="1rem" color="warning" />}
                              >
                                {params.row.nome}
                              </Alert></>
                            } else {
                              return <><Alert
                                severity="primary"
                                icon={<CircularProgress size="1rem" color="success" />}
                              >
                                {params.row.nome}
                              </Alert></>
                            }


                          }
                        }, {
                          field: 'info',
                          headerName: 'Informações',
                          flex: true,
                          sortable: false,
                          filterable: false,
                          disableColumnMenu: true,
                          renderCell: (params) => {
                            if (params.row.finalizado) {
                              return <><Alert
                                severity="warning"
                                icon={false}
                              >
                                Finalizado
                              </Alert></>
                            } else {
                              return <><Alert
                                severity="primary"
                                icon={false}
                              >
                                Em andamento - {params.row.progresso}%
                              </Alert></>
                            }

                          }
                        }, {
                          field: 'lives',
                          headerName: 'Lives',
                          sortable: false,
                          filterable: false,
                          disableColumnMenu: true,
                          renderCell: (params) => {
                            if (params.row.finalizado) {
                              return <><Alert
                                severity="warning"
                                icon={false}
                              >
                                {params.row.lives}
                              </Alert></>
                            } else {
                              return <><Alert
                                severity="primary"
                                icon={false}
                              >
                                {params.row.lives}
                              </Alert></>
                            }


                          }
                        }, {
                          field: 'total',
                          headerName: 'Total',
                          sortable: false,
                          filterable: false,
                          disableColumnMenu: true,
                          renderCell: (params) => {
                            if (params.row.finalizado) {
                              return <><Alert
                                severity="warning"
                                icon={false}
                              >
                                {params.row.total}
                              </Alert></>
                            } else {
                              return <><Alert
                                severity="primary"
                                icon={false}
                              >
                                {params.row.total}
                              </Alert></>
                            }


                          }
                        }, {
                          field: 'acoes',
                          headerName: 'Gerenciar',
                          flex: true,
                          sortable: false,
                          filterable: false,
                          disableColumnMenu: true,
                          renderCell: (params) => {
                            if (params.row.finalizado) {
                              return (<>
                                <Button onClick={() => fetchResultsGerador(params.row.id)} variant="contained" color="success" size="small" sx={{ color: "white" }}><ChecklistIcon /></Button>&nbsp;
                                <Button onClick={() => deleteGerador(params.row.id)} variant="contained" color="error" size="small"><DeleteIcon /></Button>
                              </>);
                            } else {
                              return (<>
                                <Button onClick={() => fetchResultsGerador(params.row.id)} variant="contained" color="success" size="small" sx={{ color: "white" }}><ChecklistIcon /></Button>&nbsp;
                                <Button onClick={() => pauseGerador(params.row.id)} variant="contained" color="warning" size="small" sx={{ color: "white" }}><PauseIcon /></Button>&nbsp;
                                <Button onClick={() => deleteGerador(params.row.id)} variant="contained" color="error" size="small"><DeleteIcon /></Button>
                              </>);
                            }
                            

                          }

                        }]}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        hideFooter
                      />    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <br /><br /><br /><br />
            <Typography variant="body2" color="text.secondary" align="center" >
              <Link color="inherit" href="https://centralda20.com/">
                Central Da20
              </Link>{' © 2020-'}
              {new Date().getFullYear()}
              {'. Seja bem-vindo (a). Você está no maior HUB de ferramentas do 7.'}
            </Typography>
          </Container>
        </Box>
      </Box>

      <Dialog fullScreen open={openMinhasInstancias}
        onClose={toggleMinhasInstancias(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleMinhasInstancias(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Minhas Instâncias
            </Typography>
            <Button autoFocus color="inherit" onClick={toggleNovaInstancia(true)}>
              Criar nova Instância
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            localeText={{ noRowsLabel: "Nenhuma instância criada ainda." }}

            rows={[...listaInstanciasAndamento, ...listaInstanciasFinalizadas.reverse()]}
            columns={[{
              field: 'modulo',
              headerName: 'Módulo',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {

                if (!params.row.finalizado) {
                  return <><Alert
                    severity="primary"
                    icon={<CircularProgress size="1rem" color="success" />}
                  >
                    {params.row.modulo}
                  </Alert></>
                } else {
                  return <><Alert
                    severity='warning'
                    icon={<PauseIcon size="small" />}
                  >
                    {params.row.modulo}
                  </Alert></>
                }
              }
            }, {
              field: 'info',
              headerName: 'Informações',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {

                if (!params.row.finalizado) {
                  return <><Alert
                    severity="primary"
                    icon={false}
                  >
                    Em andamento - {params.row.progresso}%
                  </Alert></>
                } else {
                  return <><Alert
                    severity="warning"
                    icon={false}
                  >
                    Finalizado
                  </Alert></>
                }
              }
            }, {
              field: 'lives',
              headerName: 'Lives',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {

                if (!params.row.finalizado) {
                  return <><Alert
                    severity="primary"
                    icon={false}
                  >
                    {params.row.lives}
                  </Alert></>
                } else {
                  return <><Alert
                    severity="warning"
                    icon={false}
                  >
                    {params.row.lives}
                  </Alert></>
                }
              }
            }, {
              field: 'dies',
              headerName: 'Dies',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {

                if (!params.row.finalizado) {
                  return <><Alert
                    severity="primary"
                    icon={false}
                  >
                    {params.row.dies}
                  </Alert></>
                } else {
                  return <><Alert
                    severity="warning"
                    icon={false}
                  >
                    {params.row.dies}
                  </Alert></>
                }
              }
            }, {
              field: 'total',
              headerName: 'Total',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {
                if (!params.row.finalizado) {
                  return <><Alert
                    severity="primary"
                    icon={false}
                  >
                    {params.row.lives + params.row.dies}/{params.row.total}
                  </Alert></>
                } else {
                  return <><Alert
                    severity="warning"
                    icon={false}
                  >
                    {params.row.lives + params.row.dies}/{params.row.total}
                  </Alert></>
                }
              }
            }, {
              field: 'criacao',
              headerName: 'Data de criação',
              sortable: false,
              filterable: false,
              flex: true,
              disableColumnMenu: true,
              renderCell: (params) => {
                var sev = "primary";
                var txt = `${moment(params.row.data_criado).format('DD/MM/YYYY HH:mm:ss')}`;
                if (params.row.finalizado) {
                  sev = "warning";
                  txt = `${moment(params.row.data_criado).format('DD/MM/YYYY HH:mm:ss')} --- ${moment(params.row.data_finalizado).format('DD/MM/YYYY HH:mm:ss')}`;
                }

                return <><Alert
                  severity={sev}
                  icon={false}
                >
                  {txt}
                </Alert></>
              }
            }, {
              field: 'acoes',
              headerName: 'Gerenciar',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {

                if (params.row.finalizado == false && params.row.andamento) {
                  return (<>
                    <Button onClick={() => fetchResults(params.row.id, true)} variant="contained" color="success" size="small" sx={{ color: "white" }}><ChecklistIcon /></Button>&nbsp;
                    <Button onClick={() => pauseInstance(params.row.id, true)} variant="contained" color="warning" size="small" sx={{ color: "white" }}><PauseIcon /></Button>&nbsp;
                    <Button onClick={() => deleteInstance(params.row.id, true)} variant="contained" color="error" size="small"><DeleteIcon /></Button>
                  </>);
                } else {
                  return (<>
                    <Button onClick={() => fetchResults(params.row.id, true)} variant="contained" color="success" size="small" sx={{ color: "white" }}><ChecklistIcon /></Button>&nbsp;
                    <Button onClick={() => replayInstance(params.row.id, true)} variant="contained" color="warning" size="small" sx={{ color: "white" }}><ReplayIcon /></Button>&nbsp;
                    <Button onClick={() => deleteInstance(params.row.id, true)} variant="contained" color="error" size="small"><DeleteIcon /></Button>
                  </>);
                }
              }

            }]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick

          />    </Box>

      </Dialog>


      <Dialog open={openLogin} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >
        <DialogTitle>Autentique-se</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Box sx={{ display: displayLoginError }}><Alert severity="error" sx={{}}>Chave inválida, revise-a e tente novamente.</Alert><br /></Box>

          <TextField
            id="input-with-icon-textfield"
            label="Insira sua chave de acesso"
            maxWidth={"lg"}
            fullWidth={true}
            onChange={(params) => {
              if (params.target.value.length == 32) {
                Autenticar(params.target.value);
              }
            }}

            InputProps={{
              type: "password",
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <br />
          <br />
          <Divider></Divider>
          <List sx={{ pt: 0 }}>
            {[... new Set(JSON.parse(localStorage.session))].map((session) => {
              const imageUrl = `https://api.dicebear.com/9.x/pixel-art/svg?seed=${encodeURIComponent(session.user)}&backgroundColor[]=`;
              return (<><ListItem disableGutters key={session.user}>
                <ListItemButton >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "transparent" }}>
                      <img src={imageUrl} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={session.user} />
                  <ButtonGroup variant="outlined" sx={{ float: "right" }}>
                    <Button onClick={() => Autenticar(session.key)} size='sm' sx={{ float: "right" }} variant='outlined'>LOGAR&nbsp;&nbsp;<LoginIcon  /></Button>
                    <Button onClick={() => showKey(session.key)} size='sm' sx={{ float: "right" }} variant='outlined'><VisibilityIcon  /></Button>
                    <Button onClick={() => RemoveKeyFromSessions(session.key)} size='sm' variant='outlined' color="error"><CloseIcon  /></Button>
                  </ButtonGroup>


                </ListItemButton>
              </ListItem><Divider></Divider></>);
            })}

          </List>

          <br />
          {openRegister ? <><TextField inputProps={{ maxLength: 32 }} label="Qual vai ser seu usuário?" variant="standard" fullWidth maxWidth="lg" onChange={(params) => setValueUserRegister(params.target.value)} /><br /><br /></> : null}


          <Button variant="contained" color='success' maxWidth="lg" fullWidth sx={{ color: "white" }} onClick={() => {
            if (openRegister) {
              setLoading(true);
              setOpenLogin(false);
              $.getJSON("https://api.ipify.org?format=json",
                function (data) {
                  socket.emit("registerUser", { user: valueUserRegister, ip: data.ip });
                })
            } else {
              setOpenRegister(true);
            }
          }}>Crie uma conta</Button>
        </DialogContent>

      </Dialog>

      <Dialog open={openCriarInstancia} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Selecione o módulo<IconButton
          edge="start"
          color="inherit"
          onClick={toggleNovaInstancia(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <TextField label="pesquise" variant="outlined" sx={{ width: "100%" }} onChange={(params) => filtrarPesquisa(params)} /><br /><br />

          <DataGrid
            rows={listaInstancias}

            columns={[{
              field: 'modulo',
              headerName: 'Módulo',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'info',
              headerName: 'Informações',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'valor_live',
              headerName: 'Valor LIVE',
              flex: false,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'valor_die',
              headerName: 'Valor DIE',
              flex: false,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'last_live',
              headerName: 'Última LIVE',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
            }]}
            onRowClick={(params) => {
              setInstanciaSelecionada(params.row);
              setOpenCriarInstancia(false);
              setOpenInserirLista(true);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick

          />
        </DialogContent>

      </Dialog>


      <Dialog open={openInserirLista} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Selecione a lista<IconButton
          edge="start"
          color="inherit"
          onClick={toggleInserirLista(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
          &nbsp;
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              toggleInserirLista(false);
              setOpenCriarInstancia(true);
            }}
            aria-label="close"
            sx={{ float: "right" }}
          >
            <ArrowBackIcon />
          </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>

          <Box>
            <TextField
              label={labelLista}
              multiline
              onChange={(params) => {
                setValueLista(params.target.value);
                var lista = params.target.value.split("\n");
                lista = lista.slice(0, limiteLinhas);
                setLabelLista(`${lista.length}/${limiteLinhas} linhas inseridas`);
                setValueLista(lista.join("\n"));
              }}
              inputProps={{ id: "lista" }}
              fullWidth
              sx={{
                whiteSpace: "pre",
                overflowX: "auto"
              }}
              value={valueLista}
              maxRows={3}
              minRows={0}
              maxWidth={"lg"}
              rows={8}
            /><br /><br />



            <Button
              component="label"
              variant="contained"
              tabIndex={-1}
              disabled={disabledUploadButton}
              color='success'
              sx={{ color: "white", float: "left" }}
              startIcon={<CloudUploadIcon />}
            >
              {labelUploadLista}
              <VisuallyHiddenInput key={inputListKey} accept=".txt" type="file"
                onChange={handleFileChange}
              />
            </Button>



            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              color='success'
              onClick={() => criarInstancia()}
              sx={{ color: "white", float: "right" }}
            >
              Criar instância
            </Button>
          </Box>
        </DialogContent>

      </Dialog>


      <Dialog open={openCriarGerador} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Selecione o módulo gerador e a quantidade<IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpenCriarGerador(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <br /><center>
            <Slider
              defaultValue={10}
              valueLabelDisplay="on"
              shiftStep={200}

              valueLabelFormat={(value) => {
                livesGerador = value;
                return `${value} lives`
              }}
              onChange={(params) => {
                livesGerador = params.target.value;
                if (geradorSelecionado) {
                  setInfoGeradores(<><Typography variant='h5' sx={{ float: "left" }}>Selecionado: {geradorSelecionado.modulo} - Custo máximo: R$ {(params.target.value * geradorSelecionado.valor_live).toFixed(2)}</Typography></>)
                }
              }}
              step={100}
              marks
              sx={{ width: "95%", float: "center" }}
              min={10}
              max={5000}
            /></center>
          <DataGrid
            rows={listaGeradores}
            columns={[{
              field: 'modulo',
              headerName: 'Módulo',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'info',
              headerName: 'Informações',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'last_live',
              headerName: 'Última LIVE',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'valor_live',
              headerName: 'Valor por LIVE',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
            }]}

            onRowClick={(params) => {

              setInfoGeradores(<><Typography variant='h5' sx={{ float: "left" }}>Selecionado: {params.row.modulo} - Custo máximo: R$ {(livesGerador * params.row.valor_live).toFixed(2)}</Typography></>)
              setGeradorSelecionado(params.row);
              setShowGeradorInfo(true);

            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick

          />
          <br />
          {showGeradorInfo ? infoGeradores : null}
          <Button disabled={!showGeradorInfo} sx={{ float: "right", color: "white", backgroundColor: "purple" }} variant='contained' onClick={() => iniciarGerador()}>INICIAR GERADOR</Button>
        </DialogContent>

      </Dialog>

      <Dialog open={openRecargas} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Suas Recargas<IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpenRecargas(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <DataGrid
            rows={listaRecargas}
            columns={[{
              field: 'status',
              headerName: 'Status',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'valor',
              headerName: 'Valor',
              flex: true,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            }, {
              field: 'data_criado',
              headerName: 'Data de criação',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {
                return (moment(params.row.data_criado).format('DD/MM/YYYY HH:mm'))
              }
            }, {
              field: 'data_expira',
              headerName: 'Data de expiração',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {
                return (moment(params.row.data_expira).format('DD/MM/YYYY HH:mm'))
              }
            }, {
              field: 'copia_cola',
              headerName: 'Código de pagamento',
              sortable: false,
              flex: true,
              filterable: false,
              disableColumnMenu: true,
              renderCell: (params) => {
                var disabledbtn = true;
                if (!params.row.pago && !params.row.expirado) {
                  disabledbtn = false;
                }

                if (params.row.crypto) {
                  return (<>
                    <Button disabled={disabledbtn} onClick={() => window.open(params.row.invoice_url, '_blank')} variant="contained" color="success" size="small" sx={{ color: "white" }}>PAGAR</Button>
                  </>);
                } else {
                  return (<>
                    <Button disabled={disabledbtn} onClick={() => navigator.clipboard.writeText(params.row.copia_cola)} variant="contained" color="success" size="small" sx={{ color: "white" }}>COPIAR QRCODE</Button>
                  </>);
                }

              }
            }
            ]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick

          />
        </DialogContent>

      </Dialog>


      <Dialog open={openConsultas} maxWidth={'lg'} fullWidth={true} fullScreen sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Consultas<IconButton
          edge="start"
          color="inherit"

          onClick={toggleConsultas(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <TextField
            select
            label="Tipo"
            onChange={(params) => {
              setTipoConsulta(params.target.value);
            }}
            defaultValue="CPF"
            helperText="Selecione o tipo de consulta"
          >
            <MenuItem key={"cpf"} value={"cpf"}>
              CPF
            </MenuItem>
            <MenuItem key={"nome"} value={"nome"}>
              Nome
            </MenuItem>
            <MenuItem key={"celular"} value={"celular"}>
              Celular (DDD+NÚMERO)
            </MenuItem>
            <MenuItem key={"email"} value={"email"}>
              E-mail
            </MenuItem>

          </TextField>&nbsp;&nbsp;
          <TextField onChange={(params) => {
            setValorConsulta(params.target.value);
          }} helperText="Digite o CPF, Nome, Celular ou E-mail" label="Quem quer encontrar?" variant="outlined" sx={{ width: "80%" }} />
          <br /><br />
          <Button onClick={() => consultaStart()} disabled={valorConsulta.length > 0 && tipoConsulta ? false : true} color="success" variant="contained" sx={{ color: "white" }}>Buscar</Button>
          <br /><br /><br /><br />
          <TextField label="Resultado do servidor" value={retornoConsulta} variant="standard" fullWidth maxWidth="lg" disabled multiline rows={20} sx={{
              whiteSpace: "pre",
              overflowX: "auto"
            }} />
        </DialogContent>

      </Dialog>

      <Dialog open={openResultsInstancia} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Resultados<IconButton
          edge="start"
          color="inherit"

          onClick={toggleResults(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <TextField
            multiline
            label={labelLivesList}
            fullWidth
            disabled
            maxWidth="lg"
            defaultValue={livesInstancia}
            rows={8}
            sx={{
              whiteSpace: "pre",
              overflowX: "auto"
            }}
          ></TextField><br /><br />
          <Button color="success" size='sm' variant='outlined' onClick={() => downloadString(livesInstancia, "txt", labelLivesList)}>DOWNLOAD</Button>&nbsp;
          <Button color="success" size='sm' variant='outlined' onClick={() => navigator.clipboard.writeText(livesInstancia)}>COPY</Button>
          <br /><br />
          <TextField
            multiline
            label={labelDiesList}
            fullWidth
            disabled
            maxWidth="lg"
            defaultValue={diesInstancia}
            rows={8}
            sx={{
              whiteSpace: "pre",
              overflowX: "auto"
            }}
          ></TextField><br /><br />
          <Button color="error" size='sm' variant='outlined' onClick={() => downloadString(diesInstancia, "txt", labelDiesList)}>DOWNLOAD</Button>&nbsp;
          <Button color="error" size='sm' variant='outlined' onClick={() => navigator.clipboard.writeText(diesInstancia)}>COPY</Button>

        </DialogContent>

      </Dialog>

      <Dialog open={openResultsGerador} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Resultados<IconButton
          edge="start"
          color="inherit"

          onClick={() => setOpenResultsGerador(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <TextField
            multiline
            label={labelLivesListGerador}
            fullWidth
            disabled
            sx={{
              whiteSpace: "pre",
              overflowX: "auto"
            }}
            maxWidth="lg"
            defaultValue={livesGeradorLista}
            rows={8}
          ></TextField>

        </DialogContent>

      </Dialog>

      <Dialog open={openAdicionais} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Adicionais<IconButton
          edge="start"
          color="inherit"

          onClick={() => setOpenAdicionais(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <Grid container spacing={3}>

            <Grid item >

              <Card>
                <CardContent>
                  <Typography variant="h5">
                    Limite de Instâncias
                  </Typography>
                  <Typography variant="h3">
                    R$ {valoresAdicionais.valor_adicional_instancia}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Limite aumentado para 5 instâncias simultâneas
                  </Typography>

                </CardContent>
                <CardActions>
                  <Button size="small" disabled={disabledAdicionais.instancias} onClick={() => buyAdicional(1)}>COMPRAR</Button>
                  {disabledAdicionais.instancias ? <><CheckIcon color="success" /> &nbsp;Adquirido</> : null}
                </CardActions>
              </Card>
            </Grid>
            <Grid item >

              <Card>
                <CardContent>
                  <Typography variant="h5">
                    Limite de Linhas
                  </Typography>
                  <Typography variant="h3">
                    R$ {valoresAdicionais.valor_adicional_linhas}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Limite aumentado para 25.000 linhas por instância
                  </Typography>

                </CardContent>
                <CardActions>
                  <Button size="small" disabled={disabledAdicionais.linhas} onClick={() => buyAdicional(2)}>COMPRAR</Button>
                  {disabledAdicionais.linhas ? <><CheckIcon color="success" /> &nbsp;Adquirido</> : null}

                </CardActions>
              </Card>
            </Grid>
            <Grid item >

              <Card>
                <CardContent>
                  <Typography variant="h5">
                    Otimização de Instância
                  </Typography>
                  <Typography variant="h3">
                    R$ {valoresAdicionais.valor_adicional_otimizacao}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Velocidade aumentada em 2x
                  </Typography>

                </CardContent>
                <CardActions>
                  <Button size="small" disabled={disabledAdicionais.otimizacao} onClick={() => buyAdicional(3)}>COMPRAR</Button>
                  {disabledAdicionais.otimizacao ? <><CheckIcon color="success" /> &nbsp;Adquirido</> : null}

                </CardActions>
              </Card>
            </Grid>
          </Grid>


        </DialogContent>

      </Dialog>

      <Dialog open={openCloud} maxWidth={'lg'} fullWidth={true} fullScreen sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Data Cloud<IconButton
          edge="start"
          color="inherit"
          disabled={cloudLoading}
          onClick={() => setOpenCloud(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>

          <code>No momento temos {loginsTotal} LOGINS armazenados em nossa database.</code>
          <br />
          <code>custo por resultado: R$ {valorPorResultado}</code>
          <br />
          <br />
          <code>Atenção! Sempre que avançar/voltar uma página, será cobrado a quantidade de resultados por página!</code>
          <br />
          <code>Você NÃO será cobrado por logins que já foram vistos por você.</code>
          <br />
          <br />
          <TextField disabled={cloudLoading} label="URL/DOMÍNIO" variant="standard" fullWidth maxWidth="lg" onChange={(params) => setInputSearchCloud(params.target.value)} />
          <br />
          <br />
          <Button disabled={cloudLoading} variant='outlined' color='success' fullWidth maxWidth="lg" onClick={() => searchCloud()}>Buscar</Button>
          <br />
          <br />
          <br />
          <br />

          <DataGrid
            rows={cloudRows}
            columns={[
              { field: 'url', headerName: 'URL', flex: true },
              { field: 'login', headerName: 'LOGIN', flex: true },
              { field: 'password', headerName: 'SENHA', flex: true }
            ]}
            pagination
            paginationMode="server"
            onPaginationModelChange={(newPage) => searchCloudWithPage(newPage)}
            paginationModel={paginationModelCloud}
            hideFooter={cloudLoading}
            loading={cloudLoading}
            hideFooterPagination={cloudLoading}
            rowCount={rowCountCloud}
          />
        </DialogContent>

      </Dialog>

      <Dialog open={openSms} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>SMS Receiver<IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpenSms(false)}
          aria-label="close"
          sx={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <Divider></Divider>

        <DialogContent>

          <code>Utilizamos somente números BRASILEIROS (+55)</code>
          <br />
          <code>Se caso o número expirar sem receber nenhum SMS, será cobrado R$ 10 de taxa.</code>
          <br />
          <code>Selecione um serviço e verifique o preço.</code>
          <br />
          <br />
          <TextField
            fullWidth
            defaultValue=""
            onChange={(params) => selectServiceSMS(params.target.value)}
            select
            label="Selecione o serviço"
          >
            {servicesSMS ? servicesSMS.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.nome}
              </MenuItem >
            )) : null}
          </TextField>
          <br />
          <br />
          <Divider></Divider>
          <br />
          <br />
          {numerosSmsList.map(rm => {
            if(!rm.sms_recebido && !rm.expirado){
              var timestamp1 = new Date().getTime();
              var timestamp2 = new Date(rm.data_expira).getTime();
              var differenceInMilliseconds = timestamp2 - timestamp1;
              var differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
              var minutes = Math.floor(differenceInSeconds / 60);
              var seconds = differenceInSeconds % 60;
              var tempoFormatado = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
              return (<><Card>
                    <CardContent>
                      <Typography gutterBottom>
                      <CircularProgress color="warning"/> &nbsp; {rm.servico.nome} | {formatPhoneNumber(rm.number)}
                      </Typography>
                      <Typography gutterBottom>
                      aguardando SMS
                      </Typography>
                      <Typography sx={{float: "right"}}>
                      expira em {tempoFormatado}
                      </Typography>
                      <br />
                      <Button  onClick={() => cancelSMS(rm._id)} size='sm' variant='outlined' color="error">CANCELAR</Button>
                    </CardContent>
                  </Card>
                  <br /></>);
            }else if(rm.sms_recebido && !rm.expirado){
              return(<><Card>
                <CardContent>
                  <Typography gutterBottom>
                  <CheckIcon size="sm" color="success"/> &nbsp; {rm.servico.nome} | {formatPhoneNumber(rm.number)}
                  </Typography>
                  <Typography gutterBottom>
                  Código recebido: {rm.sms_recebido}<br/>
                  {moment(rm.data_recebido).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                  <br />
                  <Button  onClick={() => deleteSMS(rm._id)} size='sm' variant='outlined' color="error">DELETAR</Button>
                </CardContent>
              </Card>
              <br /></>)
            }else if(!rm.sms_recebido && rm.expirado){
              return(<><Card>
                <CardContent>
                  <Typography gutterBottom>
                  <CloseIcon size="sm" color="error"/> &nbsp; {rm.servico.nome} | {formatPhoneNumber(rm.number)}
                  </Typography>
                  <Typography gutterBottom>
                  CANCELADO<br/>
                  </Typography>
                  <br />
                  <Button onClick={() => deleteSMS(rm._id)} size='sm' variant='outlined' color="error">DELETAR</Button>
                </CardContent>
              </Card>
              <br /></>)
            }
          })}
          
          
          
        </DialogContent>

      </Dialog>

      <Dialog open={OpenSeparadorEmail} maxWidth={'lg'} fullWidth={true} sx={{ backgroundColor: "rgba(0, 0, 0)" }} >

        <DialogTitle>Separador de E-mail

          <IconButton
            edge="start"
            color="inherit"
            disabled={runningSeparador}
            onClick={() => setOpenSeparadorEmail(false)}
            aria-label="close"
            sx={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
          <br />

          <code>ferramenta client-side, tenha um PC bom para listas grandes. </code>
        </DialogTitle>
        <Divider></Divider>

        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">

            <Grid item >Selecione o domínio que deseja separar<br /><br />{customList(left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"

                  size="small"
                  onClick={handleAllRight}
                  disabled={(left.length === 0 || runningSeparador == true)}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={(leftChecked.length === 0 || runningSeparador == true)}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={(rightChecked.length === 0 || runningSeparador == true)}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={(right.length === 0 || runningSeparador == true)}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>Domínios selecionados: {right.length}<br /><br />{customList(right)}</Grid>
            <Grid item>
              
              <Button
                component="label"
                variant="contained"
                disabled={(right.length == 0 ? true : false) || (runningSeparador == true)}
                tabIndex={-1}
                color='success'
                sx={{ color: "white", float: "left" }}
                startIcon={<CloudUploadIcon />}
              >
                CARREGAR LISTA DE E-MAIL & INICIAR
                <VisuallyHiddenInput accept=".txt" type="file"
                  key={inputKey}
                  onChange={handleFileChangeSeparador}
                />
              </Button>
              <br />
            </Grid>
            <Grid item>
              <code>E-mails carregados: {emailsCarregados}</code>
            </Grid>
          </Grid>
          <br />
          <Divider></Divider>
          <br />
          <code>Progresso: {progressoEmails}% &nbsp;&nbsp; E-mails válidos: {emailsValidos}</code>
          <br />
          <br />
          <Divider></Divider>
          <br />
          <br />
          <TextField
            multiline
            variant='standard'
            disabled
            defaultValue=""
            rows={6}
            value={resultEmails}
            fullWidth
            sx={{
              whiteSpace: "pre",
              overflowX: "auto"
            }}
            label="RESULTADO (SOMENTE OS PRIMEIROS 1000 RESULTADOS, PARA TER ACESSO COMPLETO CLIQUE EM DOWNLOAD OU COPY)"
          ></TextField>
          <br />
          <br />
          <Button disabled={!runningSeparador} onClick={() => {listaEmailsSeparadorOut = []}} size='sm' color="warning" variant='outlined'>PAUSAR</Button>&nbsp;
          <Button color="success" size='sm' variant='outlined' onClick={() => downloadString(resultEmails, "txt", `${emailsValidos} emails`)}>DOWNLOAD</Button>&nbsp;
          <Button color="success" size='sm' variant='outlined' onClick={() => navigator.clipboard.writeText(resultEmails)}>COPY</Button>

        </DialogContent>

      </Dialog>


    </ThemeProvider>
  );

}

export default App;
